import styled, { css } from "styled-components";

export const InputWrapper = styled.div<{ withIcon: boolean }>`
  --input-padding: ${({ withIcon }) => (withIcon ? "3.25em" : "1em")};

  width: 100%;
  position: relative;

  @media (max-width: 768px) {
    --input-padding: ${({ withIcon }) => (withIcon ? "2.75em" : "1em")};
  }
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.grey};
    font-size: ${theme.fonts.sizes.xs};
    line-height: ${theme.fonts.lineHeights.base};
  `}
  display: block;
  text-align: left;
  margin-bottom: 4px;
`;

export const StyledInput = styled.input<{ error?: boolean }>`
  ${({ theme, error, disabled }) => css`
    border: ${theme.border.width.s} solid
      ${error ? theme.colors.red : theme.colors.blue};
    color: ${theme.colors.black};
    background: ${theme.colors.white};
    border-radius: ${theme.border.borderRadius};
    font-size: ${theme.fonts.sizes.s};
    line-height: ${theme.fonts.lineHeights.base};
    font-weight: ${theme.fonts.weights.bold};

    &::placeholder {
      font-weight: ${theme.fonts.weights.normal};
    }

    &:focus {
      border-width: ${theme.border.width.m};
    }

    &:disabled {
      color: ${theme.colors.grey};
    }
  `}

  outline: none;
  height: 48px;
  width: 100%;
  padding: 0 var(--input-padding);
`;

export const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 48px;
  width: var(--input-padding);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorMessage = styled.p<{ show: boolean }>`
  ${({ theme }) => css`
    color: ${theme.colors.red};
    font-size: ${theme.fonts.sizes.xs};
    line-height: ${theme.fonts.lineHeights.base};
    height: ${theme.fonts.lineHeights.base};
  `}

  text-align: left;
`;
