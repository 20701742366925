import { FC } from "react";
import Image from "next/image";
import { UseFormRegisterReturn } from "react-hook-form";

import SearchIcon from "../../assets/images/search.svg";
import {
  ErrorMessage,
  Icon,
  InputWrapper,
  Label,
  StyledInput,
} from "./Input.components";

interface InputProps {
  className?: string;
  errorMessage?: string;
  placeholder?: string;
  name?: string;
  withIcon?: boolean;
  type?: string;
  label?: string;
  disabled?: boolean;
  register?: UseFormRegisterReturn;
}

const Input: FC<InputProps> = ({
  className,
  errorMessage,
  register,
  withIcon,
  label,
  type,
  disabled,
  ...rest
}) => {
  return (
    <InputWrapper withIcon={!!withIcon} className={className}>
      {!!label && <Label htmlFor={register?.name}>{label}</Label>}
      {withIcon && (
        <Icon>
          <Image src={SearchIcon} alt="Search" />
        </Icon>
      )}
      <StyledInput
        disabled={disabled}
        type={type}
        error={!!errorMessage}
        {...(register && register)}
        {...rest}
      />
      <ErrorMessage show={!!errorMessage}>{errorMessage}</ErrorMessage>
    </InputWrapper>
  );
};

export default Input;
