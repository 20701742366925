import React from "react";
import type { NextPage } from "next";
import Head from "next/head";

import Hero from "../components/Hero";
import KeywordFinder from "../components/KeywordFinder";

const Home: NextPage = () => {
  return (
    <React.Fragment>
      <Head>
        <title>Amazon Insight</title>
        <meta name="description" content="Amazon insight" />
      </Head>
      <Hero />
      <KeywordFinder />
    </React.Fragment>
  );
};

export default Home;
