import { FC } from "react";

import { HeroWrapper, StyledHero } from "./Hero.components";

const Hero: FC = () => {
  return (
    <StyledHero>
      <HeroWrapper>
        <h1>Data to enrich your Amazon online business</h1>
        <p>Amazon Keyword Search</p>
      </HeroWrapper>
    </StyledHero>
  );
};

export default Hero;
