import { parseAsync } from "json2csv";
import { CSVKeywordResult, KeywordResult } from "../types/searchTypes";
import { CSV_HEADERS, LEVEL_LABELS } from "./constants";

export const getApiUrl = (term: string): string => {
  return `https://completion.amazon.com/api/2017/suggestions?lop=en_GB&client-info=amazon-search-ui&mid=ATVPDKIKX0DER&alias=aps&prefix=${term}&event=onKeyPress&suggestion-type=KEYWORD&limit=11`;
};

export const downloadCSV = (
  content: Array<KeywordResult>,
  name: string,
): void => {
  const fields = [
    {
      label: CSV_HEADERS[0],
      value: "value",
    },
    {
      label: CSV_HEADERS[1],
      value: "level",
    },
  ];

  const opts = {
    fields,
    transforms: [
      (row: KeywordResult): CSVKeywordResult => {
        const { level } = row;

        const levelLabel = `${level} - ${LEVEL_LABELS[level - 1]}`;

        return { value: row.value, level: levelLabel };
      },
    ],
  };

  parseAsync(content, opts)
    .then(csvContent => {
      var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var url = URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", name);
      document.body.appendChild(link);

      link.click();
    })
    .catch(err => console.error(err));
};
