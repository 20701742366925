import styled from "styled-components";

export const StyledNotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5em;

  @media (max-width: 768px) {
    margin-top: 3em;
  }
`;

export const Text = styled.p`
  text-align: center;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fonts.sizes.xs};
  }
`;
