import styled, { css } from "styled-components";

import Wrapper from "../Wrapper";

export const StyledHero = styled.div`
  height: 280px;
  height: calc(38.222vh - var(--nav-height));
  min-height: 280px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: 280px;
    height: calc(25vh - var(--nav-height));
    min-height: 160px;
  }
`;

export const HeroWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @media (max-width: 1024px) {
    > p {
      margin-top: 1em;
    }
  }

  @media (max-width: 768px) {
    > p {
      ${({ theme }) => css`
        font-size: ${theme.fonts.sizes.xs};
        line-height: ${theme.fonts.lineHeights.base};
      `}

      margin-top: 0.5em;
    }
  }
`;
