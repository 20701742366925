import styled, { css } from "styled-components";
import { ThemeType } from "../../styles/theme";

interface ButtonProps {
  theme: ThemeType;
  color?: string;
  background?: string;
  hoverBackground?: string;
  disabled?: boolean;
}

const Button = styled.button<ButtonProps>`
  ${({ theme, color, background }) => css`
    color: ${theme.colors[color || "white"]};
    background-color: ${theme.colors[background || "blue"]};
    border-radius: ${theme.border.borderRadius};
    font-weight: ${theme.fonts.weights.normal};
    font-size: ${theme.fonts.sizes.s};
    line-height: ${theme.fonts.lineHeights.m};

    &:hover {
      background-color: ${theme.colors[`${background || "blue"}Hover`]};
    }
  `}

  min-width: 208px;
  padding: 0.5em 3.2em;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export default Button;
