import styled, { css } from "styled-components";

import Button from "../Button";

export const StyledForm = styled.div`
  ${({ theme }) => css`
    border: ${theme.border.width.m} solid ${theme.colors.lightBlue};
    border-radius: ${theme.border.borderRadius};
  `}

  width: 100%;
  max-width: 1168px;
  margin: 0 auto;
  padding: 2.5em;
  text-align: center;
  margin-bottom: 4em;
  position: relative;

  @media (max-width: 768px) {
    padding: 1.5em;
  }
`;

export const FormWrapper = styled.div<{ animate: boolean }>`
  transition: opacity 0.2s ease-in-out;
  transition-delay: 0s;

  opacity: ${({ animate }) => (animate ? 0 : 1)};
`;

export const Heading = styled.h2``;

export const Subheading = styled.p`
  margin: 0.2222em auto 1.7778em auto;
  max-width: 600px;

  @media (max-width: 768px) {
    ${({ theme }) => css`
      font-size: ${theme.fonts.sizes.xs};
      line-height: ${theme.fonts.lineHeights.base};
    `}
  }
`;

export const FormBody = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InputsWrapper = styled.div`
  display: flex;
  margin-bottom: 1em;
  position: relative;
  width: 100%;
  max-width: 864px;

  > * + * {
    margin-left: 2em;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;

    > * + * {
      margin-left: 0;
    }
  }
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.red};
    font-size: ${theme.fonts.sizes.xs};
    line-height: ${theme.fonts.lineHeights.s};
  `}

  position: absolute;
  bottom: -3px;
  left: 0;
  height: 24px;
  margin: 0;
  text-align: left;
`;

export const StyledButton = styled(Button)`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SuccessMessage = styled.p<{ animate: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  transition-delay: 0.1s;

  opacity: ${({ animate }) => (animate ? 1 : 0)};
  pointer-events: ${({ animate }) => (animate ? "auto" : "none")};
`;
