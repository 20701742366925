import React, { FC, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Loader from "react-loader-spinner";
import { useMutation } from "react-query";
import theme from "../../styles/theme";
import { sendEmail } from "../../utils/apiRequests";
import { useLocalStorage } from "../../utils/hooks";

import Input from "../Input";
import { LoaderWrapper } from "../KeywordFinder/KeywordFinder.components";
import {
  ErrorMessage,
  FormBody,
  FormWrapper,
  Heading,
  InputsWrapper,
  StyledButton,
  StyledForm,
  Subheading,
  SuccessMessage,
} from "./EmailForm.components";

export type EmailFormValues = {
  name: string;
  email: string;
};

interface EmailFormProps {
  setValue: (value: any) => void;
}

const EmailForm: FC<EmailFormProps> = ({ setValue }) => {
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<EmailFormValues>({ reValidateMode: "onBlur" });

  const { isLoading, isSuccess, isError, mutate } = useMutation(sendEmail);

  const onSubmit: SubmitHandler<EmailFormValues> = formValues => {
    mutate(formValues);
  };

  useEffect(() => {
    if (isSuccess) {
      setValue(true);
    }
  }, [isSuccess, setValue]);

  const disabled = isLoading || isSuccess;

  return (
    <StyledForm>
      <FormWrapper animate={isSuccess}>
        <Heading>Want more amazing insights?</Heading>
        <Subheading>
          Leave your name and email, and we will invite you to test the full
          version, including related searches, add content score, competitor
          analysis and much more...
        </Subheading>
        <FormBody onSubmit={handleSubmit(onSubmit)}>
          <InputsWrapper>
            <Input
              disabled={disabled}
              errorMessage={errors?.name?.message}
              register={register("name", {
                required: { value: true, message: "This field is required" },
              })}
              label="Name"></Input>
            <Input
              disabled={disabled}
              type="email"
              errorMessage={errors?.email?.message}
              register={register("email", {
                required: { value: true, message: "This field is required" },
              })}
              label="E-mail"></Input>
            {isError && (
              <ErrorMessage>
                Sorry, there was an error, please try again.
              </ErrorMessage>
            )}
          </InputsWrapper>
          <StyledButton disabled={disabled} type="submit">
            {isLoading ? (
              <LoaderWrapper>
                <Loader
                  height={32}
                  color={theme.colors.white}
                  visible={isLoading}
                  type="TailSpin"
                />
              </LoaderWrapper>
            ) : (
              "Send"
            )}
          </StyledButton>
        </FormBody>
      </FormWrapper>
      <SuccessMessage animate={isSuccess}>
        {"Thank you, we will stay in touch ;-)"}
      </SuccessMessage>
    </StyledForm>
  );
};

export default EmailForm;
