import React, { Fragment, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import Loader from "react-loader-spinner";
import { KeywordResult } from "../../types/searchTypes";
import { getKeywords } from "../../utils/apiRequests";
import { downloadCSV } from "../../utils/helpers";
import { LEVEL_LABELS } from "../../utils/constants";
import theme from "../../styles/theme";

import Button from "../Button";
import {
  CSVButton,
  Divider,
  Form,
  KeywordList,
  KeywordListHeader,
  KeywordTag,
  LoaderWrapper,
  StyledWrapper,
  Total,
} from "./KeywordFinder.components";
import NotFound from "./NotFound";
import EmailForm from "../EmailForm";
import Input from "../Input";
import { useLocalStorage } from "../../utils/hooks";

interface FormValues {
  term: string;
}

const KeywordFinder = () => {
  const [storedValue, setValue] = useLocalStorage("submitted", false);

  const [term, setTerm] = useState("");
  const [showForm, setShowForm] = useState(true);
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<FormValues>({ reValidateMode: "onBlur" });

  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    ["Term query", term],
    () => getKeywords(term),
    { enabled: !!term },
  );

  useEffect(() => {
    if (storedValue) {
      setShowForm(false);
    }
  }, [term]);

  const levelColors = [
    "lightBlue",
    "lightPurple",
    "lightTurquoise",
    "lightGreen",
  ];

  const onSubmit: SubmitHandler<FormValues> = formValues => {
    setTerm(formValues.term);
    refetch();
  };

  const handleCSVDownload = useCallback(
    () =>
      downloadCSV(
        data?.resultsData?.results?.flat().flat(),
        `${term}-search-results.csv`,
      ),
    [data, term],
  );

  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          withIcon={true}
          errorMessage={errors?.term?.message || ""}
          register={register("term", {
            required: { value: true, message: "This field is required" },
          })}
          placeholder="Search"
        />
        <Button type="submit">Search</Button>
      </Form>
      {isSuccess && (
        <>
          {data?.resultsData?.results.length > 1 && (
            <Total>Found {data.resultsData.resultsCount} results</Total>
          )}
          <Divider />
          {data?.resultsData?.results.length <= 1 ? (
            <NotFound />
          ) : (
            <>
              <KeywordListHeader>
                <CSVButton onClick={handleCSVDownload} background="darkBlue">
                  Download CSV
                </CSVButton>
              </KeywordListHeader>
              {data.resultsData.results.map(
                (keywordList: KeywordResult[], i: number) => {
                  return (
                    <Fragment key={keywordList[0].value}>
                      <p>
                        Level {i + 1} - {LEVEL_LABELS[i]}
                      </p>
                      <KeywordList>
                        {keywordList.map(elem => (
                          <KeywordTag $color={levelColors[i]} key={elem.value}>
                            {elem.value}
                          </KeywordTag>
                        ))}
                      </KeywordList>
                      {i === 0 && showForm && <EmailForm setValue={setValue} />}
                    </Fragment>
                  );
                },
              )}
            </>
          )}
        </>
      )}
      {isError && <p>Sorry, there was an error, please try again.</p>}
      <LoaderWrapper>
        <Loader color={theme.colors.blue} visible={isLoading} type="TailSpin" />
      </LoaderWrapper>
    </StyledWrapper>
  );
};

export default KeywordFinder;
