import * as Sentry from "@sentry/nextjs";
import { EmailFormValues } from "../components/EmailForm";

export const getKeywords = async (term: string) => {
  try {
    const res = await fetch(`/api/get-keywords`, {
      method: "POST",
      body: JSON.stringify({
        term,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    });
    return res.json();
  } catch (e) {
    Sentry.captureException(new Error("NextJS get keywords api error"));
    throw new Error((e as Error).message);
  }
};

export const sendEmail = async (emailFormValues: EmailFormValues) => {
  const res = await fetch(`/api/send-email`, {
    method: "POST",
    body: JSON.stringify({ emailFormValues }),
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
  });

  if (res.ok) {
    return res.json();
  } else {
    Sentry.captureException(new Error("NextJS send email api error"));
    throw new Error(res.statusText);
  }
};
