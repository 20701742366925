import styled, { css } from "styled-components";

import Button from "../Button";
import Wrapper from "../Wrapper";

export const StyledWrapper = styled(Wrapper)`
  padding-top: 3em;
  padding-bottom: 3em;
`;

export const Form = styled.form`
  max-width: 928px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 6em;

  @media (max-width: 768px) {
    width: 100%;

    ${Button} {
      width: 100%;
    }
  }
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.lightBlue};
    width: 100%;
    height: 1px;
  `}
`;

export const KeywordListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const CSVButton = styled(Button)`
  display: flex;
  margin-left: auto;
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  @media (max-width: 768px) {
    margin-bottom: 2em;
  }
`;

export const Total = styled.p`
  @media (max-width: 768px) {
    text-align: right;
  }
`;

export const KeywordList = styled.ul`
  margin-top: 1em;
  margin-bottom: 4em;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  align-items: center;
`;

export const KeywordTag = styled.li<{ $color: string }>`
  ${({ theme, $color }) => css`
    background: ${theme.colors[$color]};
    font-size: ${theme.fonts.sizes.s};
    line-height: ${theme.fonts.lineHeights.s};
    border-radius: ${theme.border.borderRadius};

    @media (max-width: 768px) {
      font-size: ${theme.fonts.sizes.xs};
    }
  `}

  display: inline-block;
  padding: 0.5em 0.75em;
  margin: 0 1em 1.25em 0;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
