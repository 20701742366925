import { FC } from "react";
import Image from "next/image";

import NotFoundImage from "../../../assets/images/results_not_found.svg";
import { StyledNotFound, Text } from "./NotFound.components";

const NotFound: FC = () => {
  return (
    <StyledNotFound>
      <Image src={NotFoundImage} alt="Results not found" />
      <Text>Sorry, we couldn’t find any results.</Text>
    </StyledNotFound>
  );
};

export default NotFound;
